
.transition-group {
position: relative;
}

.pageSlider-enter {
opacity: 0;
transform: scale(1.05);
}

.pageSlider-enter-active {
opacity: 1;
transform: scale(1);
transition: opacity 300ms, transform 300ms;
}

.pageSlider-exit {
    opacity: 1;
    transform: scale(1);
}

.pageSlider-exit-active {
    opacity: 0;
    transform: scale(0.9);
}

